import React from 'react'
import PropTypes from 'prop-types'

import FreeQuoteForm from './freeQuoteForm'
import ContactDetails from './contactDetails'
import './contact.scss'

const FreeQuote = ({ location }) => (
  <div id='freeQuoteSection' className='contact-container page-section'>
    <h2>Free Quote</h2>
    <p>
      Please fill out and submit this form and we will contact you as soon as possible
    </p>
    <FreeQuoteForm location={location} />
    <ContactDetails />
  </div>
)

FreeQuote.propTypes = {
  location: PropTypes.object,
}

export default FreeQuote
